import { useStaticQuery, graphql } from 'gatsby';

const V2DXsystemDevelopmentImages = image => {
  const data = useStaticQuery(graphql`
    query {
      FVimg: file(
        relativePath: { eq: "v2-dx-system-development/FVimg@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 214, maxHeight: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BtnDX: file(
        relativePath: { eq: "v2-dx-system-development/btn-about-dx@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 127) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BtnDXhr: file(
        relativePath: { eq: "v2-dx-system-development/btn-about-dx-hr@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 127) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Step1Img01: file(
        relativePath: { eq: "v2-dx-system-development/step1-01@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 432, maxHeight: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Step1Img02: file(
        relativePath: { eq: "v2-dx-system-development/step1-02@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 432, maxHeight: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Step1Img03: file(
        relativePath: { eq: "v2-dx-system-development/step1-03@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 432, maxHeight: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Step2Img01: file(
        relativePath: { eq: "v2-dx-system-development/step2-01@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 432, maxHeight: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Step2Img02: file(
        relativePath: { eq: "v2-dx-system-development/step2-02@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 432, maxHeight: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Step2Img03: file(
        relativePath: { eq: "v2-dx-system-development/step2-03@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 432, maxHeight: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      AIimg01: file(
        relativePath: { eq: "v2-dx-system-development/tech/AI-01@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      AIimg02: file(
        relativePath: { eq: "v2-dx-system-development/tech/AI-02@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      AIimg03: file(
        relativePath: { eq: "v2-dx-system-development/tech/AI-03@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      IoTimg01: file(
        relativePath: { eq: "v2-dx-system-development/tech/IoT-01@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      IoTimg02: file(
        relativePath: { eq: "v2-dx-system-development/tech/IoT-02@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      IoTimg03: file(
        relativePath: { eq: "v2-dx-system-development/tech/IoT-03@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ComunicationImg01: file(
        relativePath: {
          eq: "v2-dx-system-development/tech/communication-01@3x.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ComunicationImg02: file(
        relativePath: {
          eq: "v2-dx-system-development/tech/communication-02@3x.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ComunicationImg03: file(
        relativePath: {
          eq: "v2-dx-system-development/tech/communication-03@3x.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      WebSystemImg01: file(
        relativePath: {
          eq: "v2-dx-system-development/tech/WebSystem-01@3x.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      WebSystemImg02: file(
        relativePath: {
          eq: "v2-dx-system-development/tech/WebSystem-02@3x.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      WebSystemImg03: file(
        relativePath: {
          eq: "v2-dx-system-development/tech/WebSystem-03@3x.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ExDemo: file(
        relativePath: { eq: "v2-dx-system-development/tech/ex/demo.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256, maxHeight: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      TechAiDeta01: file(
        relativePath: {
          eq: "v2-dx-system-development/tech/technology_exsample_aideta_01.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 256, maxHeight: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      TechAiDeta02: file(
        relativePath: {
          eq: "v2-dx-system-development/tech/technology_exsample_aideta_02.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 256, maxHeight: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      TechIot01: file(
        relativePath: {
          eq: "v2-dx-system-development/tech/technology_exsample_iot_01.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 256, maxHeight: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      TechIot02: file(
        relativePath: {
          eq: "v2-dx-system-development/tech/technology_exsample_iot_02.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 256, maxHeight: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      TechIot03: file(
        relativePath: {
          eq: "v2-dx-system-development/tech/technology_exsample_iot_03.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 256, maxHeight: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      TechWebRtc01: file(
        relativePath: {
          eq: "v2-dx-system-development/tech/technology_exsample_webrtc_01.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 256, maxHeight: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      TechWebRtc02: file(
        relativePath: {
          eq: "v2-dx-system-development/tech/technology_exsample_webrtc_02.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 256, maxHeight: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      TechWebRtc03: file(
        relativePath: {
          eq: "v2-dx-system-development/tech/technology_exsample_webrtc_03.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 256, maxHeight: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return data[image].childImageSharp.fluid;
};

export default V2DXsystemDevelopmentImages;
