import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import PageRoot from '../../components/common-components/templates/page-root-v2';
import '../../styles/foundation-v2.css';

import IconAI from '../../images-v2/svg/icon-AI.svg';
import IconIoT from '../../images-v2/svg/icon-IoT.svg';
import IconCommunication from '../../images-v2/svg/icon-communication.svg';
import IconConsulting from '../../images-v2/svg/icon-consulting.svg';
import IconDesign from '../../images-v2/svg/icon-design.svg';
import IconOperation from '../../images-v2/svg/icon-operation.svg';
import IconWebSystem from '../../images-v2/svg/icon-webSystem.svg';
import colorPaletteV2 from '../../styles/color-palette-v2';
import pagesV2 from '../../constants-v2/pages-v2';

import V2DXsystemDevelopmentImages from '../../images/gatsby-image/v2-dx-system-development/fluid-images';
import WorkFluidImages from '../../images/gatsby-image/v2-work-page/fluid-images';
import SEOHeaderV2 from '../../components/common-components/templates/page-root-v2/seo-v2';

const SectionWrapper = styled.section`
  margin-bottom: 120px;
  @media (min-width: 960px) {
    margin-bottom: 180px;
  }
  p {
    font-size: 16px;
    font-size: 16px;
  }
`;

const AboutDXTitle = styled.h1`
  font-weight: 700;
  margin-bottom: 18px;
  margin-bottom: 18px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  .txtL {
    font-size: 36px;
    font-size: 36px;
  }
  .txtM {
    font-size: 24px;
    font-size: 24px;
  }
  .txtL2 {
    font-size: 40px;
    font-size: 40px;
  }
  @media (min-width: 960px) {
    .txtL {
      font-size: 50px;
      font-size: 50px;
    }
    .txtM {
      font-size: 42px;
      font-size: 42px;
    }
    .txtL2 {
      font-size: 56px;
      font-size: 56px;
    }
    margin-bottom: 24px;
    margin-bottom: 24px;
  }
`;

const AboutDXsubTitle = styled.div`
  font-weight: 700;
  margin-bottom: 64px;
  margin-bottom: 64px;
  font-size: 22px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

const AboutDXdescription = styled.div`
  margin-bottom: 28px;
  margin-bottom: 28px;
  font-size: 16px;
  font-size: 16px;
  line-height: 1.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .description-txt {
    @media (min-width: 520px) {
      width: 412px;
      width: 412px;
      margin-left: auto;
      margin-right: auto;
    }
    @media (min-width: 960px) {
      width: auto;
      margin-left: 0;
      margin-right: 0;
    }
  }
  @media (min-width: 960px) {
    flex-direction: row;
  }

  p + p {
    margin-top: 16px;
    margin-top: 16px;
  }
`;

const OutsideImg = styled(Img)`
  width: 100%;

  margin-bottom: 24px;
  margin-bottom: 24px;
  @media (min-width: 520px) {
    width: 412px;
    width: 412px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 960px) {
    margin-left: 0;
    margin-right: 32px;
    margin-right: 32px;
    margin-bottom: 0;
    width: 214px;
    width: 214px;
    height: 220px;
    height: 220px;
  }
`;

const BannerImgArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 64px auto 0;
  flex-direction: column;
  @media (min-width: 960px) {
    width: 609px;
    flex-direction: row;
  }
`;
const BannerLink = styled(Link)`
  border: 2px solid;
  border-image-source: linear-gradient(
    90deg,
    rgb(44, 145, 238),
    rgb(242, 182, 44)
  );
  border-image-slice: 1;
  margin-bottom: 24px;
  margin-bottom: 24px;
  width: 288px;
  width: 288px;
  height: 127px;
  height: 127px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 960px) {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
  }
`;

const BannerImg = styled(Img)`
  width: 284px;
  width: 284px;
  height: 123px;
  height: 123px;
`;

const SectionTitle = styled.h2`
  font-weight: 700;
  margin-bottom: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-size: 38px;
  font-size: 38px;
  @media (min-width: 960px) {
    font-size: 48px;
    font-size: 48px;
  }
  line-height: 1.2;
`;
const SectionSubTitle = styled.div`
  font-weight: 700;
  margin-bottom: 18px;
  margin-bottom: 18px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-size: 20px;
  font-size: 20px;
  line-height: 1.5;
`;

const CategoryList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  font-size: 18px;
  margin-top: 40px;
  margin-top: 40px;
  margin-bottom: 32px;
  margin-bottom: 32px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 457px) {
    width: 100%;
  }
  li {
    color: #2c91ee;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1;
    img {
      margin-right: 8px;
      margin-right: 8px;
    }
  }
  li + li {
    margin-top: 24px;
    margin-top: 24px;
  }
  @media (min-width: 457px) {
    flex-direction: row;
    li + li {
      margin-top: 0;
      margin-left: 24px;
      margin-left: 24px;
    }
  }
`;

const SectionDescription = styled.p`
  text-align: center;
  line-height: 1.8;
  margin-bottom: 92px;
  margin-bottom: 92px;
`;

const DoubleLine = styled.div`
  height: 3px;
  position: relative;
  margin-left: -24px;
  margin-left: -24px;
  margin-right: -24px;
  margin-right: -24px;
  @media (min-width: 960px) {
    margin-left: 0;
    margin-right: 0;
  }
  &:before,
  :after {
    content: '';
    display: flex;
    height: 1px;
    width: 100%;
    background: linear-gradient(90deg, #2c91ee 0%, #f2b62c 100%);
  }
  &:before {
    position: absolute;
    top: 0;
  }
  &:after {
    position: absolute;
    bottom: 0;
  }
`;

const FlowDescription = styled.div`
  @media (min-width: 960px) {
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    grid-template-rows: 3px auto 3px 1fr 1fr 1fr;
    /* 二重線 */
    #double-line-1st {
      grid-row: 1 / 2;
      grid-column: 1 / 4;
    }
    #double-line-2nd {
      grid-row: 3 / 4;
      grid-column: 1 / 4;
    }
    /* 縦線 */
    .vertical-line {
      width: 1px;
      height: 100%;
      border-right: 1px dashed #8fa48e;
    }
    #vertical-line1 {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }
    #vertical-line2 {
      grid-row: 4 / 7;
      grid-column: 2 / 3;
    }
    /* 配置 */
    #phase1-title {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }
    #phase2-title {
      grid-row: 2 / 3;
      grid-column: 3 / 4;
    }
    #phase1-step1 {
      grid-row: 4 / 5;
      grid-column: 1 / 2;
    }
    #phase1-step2 {
      grid-row: 5 / 6;
      grid-column: 1 / 2;
    }
    #phase1-step3 {
      grid-row: 6 / 7;
      grid-column: 1 / 2;
    }
    #phase2-step1 {
      grid-row: 4 / 5;
      grid-column: 3 / 4;
    }
    #phase2-step2 {
      grid-row: 5 / 6;
      grid-column: 3 / 4;
    }
    #phase2-step3 {
      grid-row: 6 / 7;
      grid-column: 3 / 4;
    }
  }
  /* フェーズ */
  .phase-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 34px 0 36px 0;
    padding: 34px 0 36px 0;
    /* フェーズタイトル */
    .phase-title {
      font-size: 30px;
      font-size: 30px;
      .large {
        font-size: 36px;
        font-size: 36px;
      }
    }
    .description {
      display: block;
      text-align: center;
      font-size: 18px;
      font-size: 18px;
      margin-top: 20px;
      margin-top: 20px;
      line-height: 1.5;
      @media (min-width: 960px) {
        line-height: 1;
      }
    }
  }
`;

const FlowStep = styled.div`
  padding: 24px 24px;
  padding: 24px 24px;
  &.step1 {
    margin-top: 24px;
  }
  @media (min-width: 960px) {
    padding: 36px 52px;
    padding: 36px 52px;
    display: grid;
    grid-template-columns: 144px 1fr;
    grid-template-columns: 144px 1fr;
    grid-template-rows: 24px 1fr;
    grid-template-rows: 24px 1fr;
    grid-column-gap: 24px;
    grid-column-gap: 24px;
    grid-row-gap: 22px;
    grid-row-gap: 22px;
  }
  .step-title {
    font-size: 24px;
    font-size: 24px;
    margin-bottom: 22px;
    margin-bottom: 22px;
    justify-content: center;
    display: flex;
    @media (min-width: 960px) {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
    }
  }
  .description {
    font-size: 16px;
    font-size: 16px;
    line-height: 1.8;
    @media (min-width: 520px) {
      width: 412px;
      width: 412px;
      margin-left: auto;
      margin-right: auto;
    }
    @media (min-width: 960px) {
      width: 100%;
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }
  }
`;

const StepImg = styled(Img)`
  width: 100%;
  min-width: 144px;
  min-width: 144px;
  min-height: 120px; // 仮
  min-height: 120px; // 仮
  margin-bottom: 24px;
  margin-bottom: 24px;
  @media (min-width: 520px) {
    width: 412px;
    width: 412px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 960px) {
    margin-bottom: 0;
    width: 144px;
    width: 144px;
    height: 120px;
    height: 120px;
    margin-right: 24px;
    margin-right: 24px;
  }
`;

const TechImg = styled(Img)`
  width: 100%;
  margin-bottom: 24px;
  margin-bottom: 24px;
  @media (min-width: 520px) {
    width: 412px;
    width: 412px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 960px) {
    margin-bottom: 0;
    width: 288px;
    width: 288px;
    height: 200px;
    height: 200px;
    margin-right: 24px;
    margin-right: 24px;
  }
`;

const TechDescription = styled.div`
  margin-bottom: 140px;
  .tech-title {
    margin: -18px auto 0 auto;
    @media (min-width: 960px) {
      margin: -24px auto 0 auto;
    }
    display: flex;
    .tech-title-inner {
      font-size: 28px;
      font-size: 28px;
      @media (min-width: 960px) {
        font-size: 38px;
        font-size: 38px;
      }
      background: ${colorPaletteV2.surface};
      padding: 0 12px;
      padding: 0 12px;
      @media (min-width: 960px) {
        padding: 0 20px;
        padding: 0 20px;
      }
      display: block;
      margin: 0 auto;
      z-index: 10;
      img {
        margin-right: 10px;
        margin-right: 10px;
        width: 32px;
        width: 32px;
        height: 32px;
        height: 32px;
        @media (min-width: 960px) {
          margin-right: 20px;
          margin-right: 20px;
          width: 48px;
          width: 48px;
          height: 48px;
          height: 48px;
        }
        display: inline-block;
      }
      .title-txt {
        top: -5px;
        @media (min-width: 960px) {
          top: -9px;
        }
        position: relative;
      }
    }
  }
  .description {
    font-size: 16px;
    font-size: 16px;
    line-height: 1.8;
    text-align: center;
    margin-top: 24px;
    margin-top: 24px;
    margin-bottom: 35px;
    margin-bottom: 35px;
  }
`;

const TechWrap = styled.div`
  display: block;
  @media (min-width: 960px) {
    display: flex;
    justify-content: space-between;
  }
`;

const Tech = styled.div`
  margin-bottom: 64px;
  margin-bottom: 64px;
  @media (min-width: 960px) {
    width: 288px;
    width: 288px;
    margin-bottom: 0;
  }
  .title {
    font-size: 26px;
    font-size: 26px;
    margin-top: 22px;
    margin-top: 22px;
    margin-bottom: 18px;
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
    display: flex;
    color: #2c91ee;
  }
  .description2 {
    font-size: 16px;
    font-size: 16px;
    line-height: 1.8;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
    margin-bottom: 10px;
  }
  .ex {
    font-size: 16px;
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    text-align: center;
  }
`;

const ExampleOfUseArea = styled.div`
  margin-top: 40px;
  margin-top: 40px;
  background: ${colorPaletteV2.white};
  padding: 30px 16px 30px 16px;
  padding: 30px 16px 30px 16px;
  border-radius: 16px;
  border-radius: 16px;
  @media (min-width: 520px) {
    width: 412px;
    width: 412px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 960px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 30px 48px 42px 48px;
    padding: 30px 48px 42px 48px;
  }
  .area-title {
    font-size: 18px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding: 0 0 30px 0;
    padding: 0 0 30px 0;
    line-height: 1.2;
  }
`;

const ExampleOfUseWrap = styled.div`
  display: block;
  @media (min-width: 960px) {
    display: flex;
    justify-content: space-between;
  }
`;

const ExampleOfUse = styled.div`
  width: 100%;
  margin-bottom: 56px;
  margin-bottom: 56px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (min-width: 960px) {
    width: 256px;
    width: 256px;
    margin-bottom: 0;
  }
  .example-of-use-title {
    font-size: 18px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;
    margin-bottom: 12px;
    line-height: 1.5;
  }
  .example-of-use-txt {
    font-size: 16px;
    font-size: 16px;
    line-height: 1.5;
  }
`;

const ExampleOfUseImg = styled(Img)`
  width: 100%;
  margin: 0 auto 18px;
  margin: 0 auto 18px;
  @media (min-width: 960px) {
    max-width: 100%;
    width: 256px;
    width: 256px;
    height: 180px;
    height: 180px;
  }
`;
// TO DO:リンクについてはまた考えるためコメントアウト 20210331
// const MoreLink = styled(Link)`
//   margin: 12px 0 0 0;
//   margin: 12px 0 0 0;
//   font-size: 16px;
//   font-size: 16px;
//   display: inline-block;
//   color: ${colorPaletteV2.blue};
//   padding-left: 24px;
//   padding-left: 24px;
//   position: relative;
//   &:before {
//     content: '';
//     width: 16px;
//     height: 1px;
//     background: ${colorPaletteV2.blue};
//     display: block;
//     position: relative;
//     top: 9px;
//     top: 9px;
//     left: -24px;
//     left: -24px;
//   }
// `;

const HomePage = () => {
  return (
    <PageRoot>
      <SEOHeaderV2 pageTitle="外側からのDX（事業変革プログラム）" />
      <div className="wrapper-v2 ">
        <SectionWrapper>
          <AboutDXTitle>
            <span className="txtL">外側</span>
            <span className="txtM">からの</span>
            <span className="txtL2">DX</span>
          </AboutDXTitle>
          <AboutDXsubTitle>
            <span className="grad_txt">事業変革プログラム</span>
          </AboutDXsubTitle>
          <AboutDXdescription>
            <OutsideImg fluid={V2DXsystemDevelopmentImages('FVimg')} />
            <div className="description-txt">
              <p>
                Regnioは以下のような技術を使って、
                <br />
                リアルな現場から
                <br />
                様々な「経営に活かせるデータ」を抽出します。
              </p>
              <p>
                より一層「攻めの経営ができる」ように、また
                <br />
                現場の方々がより働きやすくなるように
              </p>
              <p>
                「人をサポートするためのシステム」を
                <br />
                パートナー企業様と二人三脚で作っていきます。
              </p>
            </div>
          </AboutDXdescription>
          <BannerImgArea>
            <BannerLink alt={pagesV2.index.name} to={pagesV2.index.path}>
              <BannerImg fluid={V2DXsystemDevelopmentImages('BtnDX')} />
            </BannerLink>
            <BannerLink alt={pagesV2.DXinside.name} to={pagesV2.DXinside.path}>
              <BannerImg fluid={V2DXsystemDevelopmentImages('BtnDXhr')} />
            </BannerLink>
          </BannerImgArea>
        </SectionWrapper>

        <SectionWrapper>
          <SectionTitle>
            <span className="grad_txt">開発の流れ</span>
          </SectionTitle>
          <SectionSubTitle>
            <span className="grad_txt">Development Flow</span>
          </SectionSubTitle>
          <CategoryList>
            <li>
              <img src={IconConsulting} alt="Consulting" />
              コンサルティング
            </li>
            <li>
              <img src={IconDesign} alt="Design" />
              デザイン
            </li>
            <li>
              <img src={IconAI} alt="AI" />
              <img src={IconIoT} alt="IoT" />
              <img src={IconCommunication} alt="Communication" />
              <img src={IconWebSystem} alt="Web System" />
              テクノロジー
            </li>
            <li>
              <img src={IconOperation} alt="Operation" />
              運用
            </li>
          </CategoryList>
          <SectionDescription>
            御社の事業に深く入り込み、
            <br />
            中長期的な
            <br className="display_only_SP" />
            事業変革パートナーとして
            <br className="display_only_SP" />
            伴走します。
          </SectionDescription>

          <FlowDescription>
            <div
              id="vertical-line1"
              className="vertical-line display_only_PC"
            />
            <div
              id="vertical-line2"
              className="vertical-line display_only_PC"
            />
            <DoubleLine id="double-line-1st" />
            <div id="phase1-title" className="phase-area">
              <h3 className="phase-title">
                <span className="grad_txt">
                  <span className="large">構想</span>フェーズ
                </span>
              </h3>
              <span className="description">1〜2ヶ月</span>
            </div>
            <DoubleLine id="double-line-2nd" />
            <FlowStep id="phase1-step1" className="step1">
              <h4 className="step-title">ヒアリング・現場視察</h4>
              <StepImg fluid={V2DXsystemDevelopmentImages('Step1Img01')} />
              <div className="description">
                現状の経営課題と現場課題を、ヒアリングと現場視察を通して整理し、業務理解を深めます。
              </div>
            </FlowStep>
            <FlowStep id="phase1-step2">
              <h4 className="step-title">調査・検討</h4>
              <StepImg fluid={V2DXsystemDevelopmentImages('Step1Img02')} />
              <div className="description">
                整理した課題を元に、必要な情報を収集しながらソリューション提案の為の踏み込んだ検討を行います。
              </div>
            </FlowStep>
            <FlowStep id="phase1-step3">
              <h4 className="step-title">提案・チーム組成</h4>
              <StepImg fluid={V2DXsystemDevelopmentImages('Step1Img03')} />
              <div className="description">
                課題解決の為の具体的なソリューションを提案し、それに必要なチーム組成を弊社側とお客様側で行います。
              </div>
            </FlowStep>
            <DoubleLine className="display_none_PC" />
            <div id="phase2-title" className="phase-area">
              <h3 className="phase-title">
                <span className="grad_txt">
                  <span className="large">実現</span>フェーズ
                </span>
              </h3>
              <span className="description">
                6ヶ月〜
                <br className="display_only_SP" />
                （プロジェクトに応じて）
              </span>
            </div>
            <DoubleLine className="display_none_PC" />
            <FlowStep id="phase2-step1" className="step1">
              <h4 className="step-title">プロトタイプ開発</h4>
              <StepImg fluid={V2DXsystemDevelopmentImages('Step2Img01')} />
              <div className="description">
                大まかな仕様のみ決定し、お客様が実際に触って検証できるプロトタイプシステムを短期間で構築します。
              </div>
            </FlowStep>
            <FlowStep id="phase2-step2">
              <h4 className="step-title">本開発</h4>
              <StepImg fluid={V2DXsystemDevelopmentImages('Step2Img02')} />
              <div className="description">
                プロトタイプシステムの検証で得られた改善点や要望を具体的な仕様に落とし込み、正式なシステムとして構築します。
              </div>
            </FlowStep>
            <FlowStep id="phase2-step3">
              <h4 className="step-title">運用</h4>
              <StepImg fluid={V2DXsystemDevelopmentImages('Step2Img03')} />
              <div className="description">
                正式システムのリリース後、安定的な運用を行うと同時に、継続的な改良と新機能の開発を行います。
              </div>
            </FlowStep>
          </FlowDescription>
        </SectionWrapper>

        <SectionWrapper>
          <SectionTitle>
            <span className="grad_txt">
              テクノロジーに
              <br className="display_only_SP" />
              ついて
            </span>
          </SectionTitle>
          <SectionSubTitle>
            <span className="grad_txt">Technologies</span>
          </SectionSubTitle>
          <CategoryList>
            <li>
              <img src={IconAI} alt="AI" />
              AI
            </li>
            <li>
              <img src={IconIoT} alt="IoT" />
              IoT
            </li>
            <li>
              <img src={IconCommunication} alt="Communication" />
              通信
            </li>
            <li>
              <img src={IconWebSystem} alt="Web System" />
              Webシステム
            </li>
          </CategoryList>
          <SectionDescription>
            幅広い技術で、
            <br className="display_only_SP" />
            あらゆるビジネス課題を解決します。
            <br />
            それぞれの技術について、
            <br className="display_only_SP" />
            説明と活用例をまとめました。
          </SectionDescription>

          <TechDescription>
            <DoubleLine />
            <h3 className="tech-title">
              <span className="tech-title-inner">
                <img src={IconAI} alt="AI" />
                <span className="grad_txt title-txt">AI・データ分析</span>
              </span>
            </h3>
            <div className="description">
              機械学習とデータ分析技術により
              <br />
              あらゆるアナログデータをデジタル化し、可視化と分析を行います。
            </div>
            <TechWrap>
              <Tech>
                <TechImg fluid={V2DXsystemDevelopmentImages('AIimg01')} />
                <h4 className="title">形状計測</h4>
                <div className="description2">
                  任意の物体の
                  <br />
                  長さや大きさを定量化します。
                </div>
                <div className="ex">例） 生産管理、品質管理などに</div>
              </Tech>
              <Tech>
                <TechImg fluid={V2DXsystemDevelopmentImages('AIimg02')} />
                <h4 className="title">数値予測</h4>
                <div className="description2">
                  任意の時系列データを学習し、
                  <br />
                  数値予測を行います。
                </div>
                <div className="ex">例） 需要予測、異常値分析などに</div>
              </Tech>
              <Tech>
                <TechImg fluid={V2DXsystemDevelopmentImages('AIimg03')} />
                <h4 className="title">動量計測</h4>
                <div className="description2">
                  任意の物体の
                  <br />
                  移動情報を定量化します。
                </div>
                <div className="ex">例） 生産管理、導線分析などに</div>
              </Tech>
            </TechWrap>
            <ExampleOfUseArea>
              <h3 className="area-title">
                <span className="grad_txt">技術活用例</span>
              </h3>
              <ExampleOfUseWrap>
                <ExampleOfUse>
                  <ExampleOfUseImg
                    fluid={V2DXsystemDevelopmentImages('TechAiDeta01')}
                  />
                  <h4 className="example-of-use-title">鋼板の形状数値化</h4>
                  <p className="example-of-use-txt">
                    鋼板の生産現場において、熟練作業者の「目」と「勘」をモデル化。生産ライン自動化を目指して、位置ズレや切れ端の幅を画像から検知し、時系列の数値データに変換。
                  </p>
                  {/* TODO:下層ページができたらここも繋げるのかなどを検討する。 20210331 */}
                  {/* <MoreLink alt="" to="">
                    事例を詳しく見る
                  </MoreLink> */}
                </ExampleOfUse>

                <ExampleOfUse>
                  <ExampleOfUseImg
                    fluid={V2DXsystemDevelopmentImages('TechAiDeta02')}
                  />
                  <h4 className="example-of-use-title">豆腐の生産計画立案</h4>
                  <p className="example-of-use-txt">
                    過去の豆腐出荷実績データから商品の需要予測を行う。需要予測と在庫状況から商品の生産計画を自動生成し、生産の最適化を行う。
                  </p>
                  {/* TODO:下層ページができたらここも繋げるのかなどを検討する。 20210331 */}
                  {/* <MoreLink alt="" to="">
                    事例を詳しく見る
                  </MoreLink> */}
                </ExampleOfUse>

                <ExampleOfUse>
                  <ExampleOfUseImg
                    fluid={V2DXsystemDevelopmentImages('TechWebRtc02')}
                  />
                  <h4 className="example-of-use-title">豆腐の生産実績計上</h4>
                  <p className="example-of-use-txt">
                    豆腐の生産現場において、生産ライン上の商品を検知し、生産個数を自動計上。入力作業で現場の作業者を煩わせることなく生産実績を自動計上する。
                  </p>
                  {/* TODO:下層ページができたらここも繋げるのかなどを検討する。 20210331 */}
                  {/* <MoreLink alt="" to="">
                    事例を詳しく見る
                  </MoreLink> */}
                </ExampleOfUse>
              </ExampleOfUseWrap>
            </ExampleOfUseArea>
          </TechDescription>

          <TechDescription>
            <DoubleLine />
            <h3 className="tech-title">
              <span className="tech-title-inner">
                <img src={IconIoT} alt="AI" />
                <span className="grad_txt title-txt">IoT</span>
              </span>
            </h3>
            <div className="description">
              エッジコンピュータにより、センサーやカメラ、 PLCを通じた
              <br />
              現場データの収集や産業機器の制御までを行います。
            </div>
            <TechWrap>
              <Tech>
                <TechImg fluid={V2DXsystemDevelopmentImages('IoTimg01')} />
                <h4 className="title">データ取得</h4>
                <div className="description2">
                  カメラや各種センサーから、
                  <br />
                  画像情報や数値情報を取得します。
                </div>
                <div className="ex">例） 生産管理、遠隔操業などに</div>
              </Tech>
              <Tech>
                <TechImg fluid={V2DXsystemDevelopmentImages('IoTimg02')} />
                <h4 className="title">データ処理</h4>
                <div className="description2">
                  取得した画像情報や数値情報に
                  <br />
                  デジタル処理を施します。
                </div>
                <div className="ex">例） 生産管理、遠隔操業などに</div>
              </Tech>
              <Tech>
                <TechImg fluid={V2DXsystemDevelopmentImages('IoTimg03')} />
                <h4 className="title">機器制御</h4>
                <div className="description2">
                  PLC（プログラマブルロジックコントローラ）や
                  <br className="display_none_PC" />
                  GPIO（汎用I/Oポート）を通じて、
                  <br className="display_none_PC" />
                  各種機器を制御します。
                </div>
                <div className="ex">例） 生産管理、遠隔操業などに</div>
              </Tech>
            </TechWrap>
            <ExampleOfUseArea>
              <h3 className="area-title">
                <span className="grad_txt">技術活用例</span>
              </h3>
              <ExampleOfUseWrap>
                <ExampleOfUse>
                  <ExampleOfUseImg
                    fluid={V2DXsystemDevelopmentImages('TechIot01')}
                  />
                  <h4 className="example-of-use-title">
                    鋼板生産ラインのデータ収集
                  </h4>
                  <p className="example-of-use-txt">
                    鋼板の生産現場において、工作機器の制御信号を取得を行う。粉塵やノイズが強い場所でも安定的に稼働するデータ収集装置を実現。
                  </p>
                  {/* TODO:下層ページができたらここも繋げるのかなどを検討する。 20210331 */}
                  {/* <MoreLink alt="" to="">
                    事例を詳しく見る
                  </MoreLink> */}
                </ExampleOfUse>

                <ExampleOfUse>
                  <ExampleOfUseImg
                    fluid={V2DXsystemDevelopmentImages('TechIot02')}
                  />
                  <h4 className="example-of-use-title">鋼板生産データの処理</h4>
                  <p className="example-of-use-txt">
                    鋼板の生産現場において、取得した制御信号に含まれるノイズ信号や不要成分を信号処理により除去。大電力システムの中でも安定して稼働するデータ収集装置を実現。
                  </p>
                  {/* TODO:下層ページができたらここも繋げるのかなどを検討する。 20210331 */}
                  {/* <MoreLink alt="" to="">
                    事例を詳しく見る
                  </MoreLink> */}
                </ExampleOfUse>

                <ExampleOfUse>
                  <ExampleOfUseImg
                    fluid={V2DXsystemDevelopmentImages('TechIot03')}
                  />
                  <h4 className="example-of-use-title">
                    鋼板生産機器の稼働データ取得
                  </h4>
                  <p className="example-of-use-txt">
                    PLCを介して、生産ライン中に設置された各種製造機器の稼働状況をリアルタイムに取得。AIモデルから生成される制御信号により機器の自動制御も行う。
                  </p>
                  {/* TODO:下層ページができたらここも繋げるのかなどを検討する。 20210331 */}
                  {/* <MoreLink alt="" to="">
                    事例を詳しく見る
                  </MoreLink> */}
                </ExampleOfUse>
              </ExampleOfUseWrap>
            </ExampleOfUseArea>
          </TechDescription>

          <TechDescription>
            <DoubleLine />
            <h3 className="tech-title">
              <span className="tech-title-inner">
                <img src={IconCommunication} alt="AI" />
                <span className="grad_txt title-txt">通信（WebRTC）</span>
              </span>
            </h3>
            <div className="description">
              自社開発のWebRTCシステムにより、様々な用途やアーキテクチャで
              <br />
              安価にリアルタイム通信サービスを提供します。
            </div>
            <TechWrap>
              <Tech>
                <TechImg
                  fluid={V2DXsystemDevelopmentImages('ComunicationImg01')}
                />
                <h4 className="title">発着信</h4>
                <div className="description2">
                  PC-スマホ間、スマホ-スマホ間の
                  <br />
                  通話呼び出しと、Video通信を行います。
                  <br />
                  QRコードからの発信も可能です。
                </div>
                <div className="ex">例） 遠隔操業、遠隔支援などに</div>
              </Tech>
              <Tech>
                <TechImg
                  fluid={V2DXsystemDevelopmentImages('ComunicationImg02')}
                />
                <h4 className="title">カメラ映像配信</h4>
                <div className="description2">
                  産業用カメラから、
                  <br />
                  ブラウザやアプリに
                  <br />
                  リアルタイム映像配信を行います。
                </div>
                <div className="ex">例） 遠隔操業、遠隔監視などに</div>
              </Tech>
              <Tech>
                <TechImg
                  fluid={V2DXsystemDevelopmentImages('ComunicationImg03')}
                />
                <h4 className="title">録画</h4>
                <div className="description2">
                  産業用カメラの映像を
                  <br />
                  録画しクラウドに保存します。
                </div>
                <div className="ex">例） 遠隔操業、品質保証などに</div>
              </Tech>
            </TechWrap>
            <ExampleOfUseArea>
              <h3 className="area-title">
                <span className="grad_txt">技術活用例</span>
              </h3>
              <ExampleOfUseWrap>
                <ExampleOfUse>
                  <ExampleOfUseImg
                    fluid={V2DXsystemDevelopmentImages('TechWebRtc01')}
                  />
                  <h4 className="example-of-use-title">
                    鋼板生産現場の映像配信
                  </h4>
                  <p className="example-of-use-txt">
                    鋼板の生産ラインの状況を動画でリアルタイムに配信。事務所からでも自宅からでも遠隔で生産ラインの監視と制御が行える環境を実現。
                  </p>
                  {/* TODO:下層ページができたらここも繋げるのかなどを検討する。 20210331 */}
                  {/* <MoreLink alt="" to="">
                    事例を詳しく見る
                  </MoreLink> */}
                </ExampleOfUse>

                <ExampleOfUse>
                  <ExampleOfUseImg
                    fluid={V2DXsystemDevelopmentImages('TechWebRtc02')}
                  />
                  <h4 className="example-of-use-title">
                    豆腐生産現現場の映像配信
                  </h4>
                  <p className="example-of-use-txt">
                    豆腐の生産ラインの状況を動画でリアルタイムに配信。事務所からでも自宅からでも遠隔で生産ラインの監視が行える環境を実現。
                  </p>
                  {/* TODO:下層ページができたらここも繋げるのかなどを検討する。 20210331 */}
                  {/* <MoreLink alt="" to="">
                    事例を詳しく見る
                  </MoreLink> */}
                </ExampleOfUse>

                <ExampleOfUse>
                  <ExampleOfUseImg
                    fluid={V2DXsystemDevelopmentImages('TechWebRtc03')}
                  />
                  <h4 className="example-of-use-title">
                    豆腐生産現場映像の録画
                  </h4>
                  <p className="example-of-use-txt">
                    生産ラインの状況を録画しクラウドに保存。第三者機関への提出資料として、または異物混入予防等の品質保証の為に活用する。
                  </p>
                  {/* TODO:下層ページができたらここも繋げるのかなどを検討する。 20210331 */}
                  {/* <MoreLink alt="" to="">
                    事例を詳しく見る
                  </MoreLink> */}
                </ExampleOfUse>
              </ExampleOfUseWrap>
            </ExampleOfUseArea>
          </TechDescription>

          <TechDescription>
            <DoubleLine />
            <h3 className="tech-title">
              <span className="tech-title-inner">
                <img src={IconWebSystem} alt="AI" />
                <span className="grad_txt title-txt">Webシステム</span>
              </span>
            </h3>
            <div className="description">
              あらゆるビジネスロジックを最適なアーキテクチャで
              <br />
              アプリケーションとして実装します。
            </div>
            <TechWrap>
              <Tech>
                <TechImg
                  fluid={V2DXsystemDevelopmentImages('WebSystemImg01')}
                />
                <h4 className="title">アプリケーション</h4>
                <div className="description2">
                  Web/モバイルアプリケーションの
                  <br />
                  実装を行います。
                </div>
                <div className="ex">
                  例） 消費者向けアプリケーション、
                  <br />
                  業務用アプリケーションなどに
                </div>
              </Tech>
              <Tech>
                <TechImg
                  fluid={V2DXsystemDevelopmentImages('WebSystemImg02')}
                />
                <h4 className="title">インフラ</h4>
                <div className="description2">
                  モノリシックアーキテクチャ、
                  <br />
                  マイクロサービスアーキテクテャ、
                  <br />
                  サーバーレスのインフラを
                  <br />
                  構築します。
                </div>
                <div className="ex">
                  例） 消費者向けアプリケーション、
                  <br />
                  業務用アプリケーションなどに
                </div>
              </Tech>
              <Tech>
                <TechImg
                  fluid={V2DXsystemDevelopmentImages('WebSystemImg03')}
                />
                <h4 className="title">セキュリティ</h4>
                <div className="description2">
                  ゼロトラストネットワーク、
                  <br />
                  オンプレミスの
                  <br />
                  クローズドネットワークによる
                  <br />
                  セキュアな環境を構築します。
                </div>
                <div className="ex">
                  例） 消費者向けアプリケーション、
                  <br />
                  業務用アプリケーションなどに
                </div>
              </Tech>
            </TechWrap>
            <ExampleOfUseArea>
              <h3 className="area-title">
                <span className="grad_txt">技術活用例</span>
              </h3>
              <ExampleOfUseWrap>
                <ExampleOfUse>
                  <ExampleOfUseImg fluid={WorkFluidImages('dxsolution')} />
                  <h4 className="example-of-use-title">
                    釣り特化型コミュニティシステムの開発と運用
                  </h4>
                  <p className="example-of-use-txt">
                    月間数百万PVを誇る釣りポータルサイトAWS上に構築。サービスをより良いものにしていく為に、DevOpsによる継続的な開発とデプロイを行っている。
                  </p>
                  {/* TODO:下層ページができたらここも繋げるのかなどを検討する。 20210331 */}
                  {/* <MoreLink alt="" to="/works/dx-solution">
                    事例を詳しく見る
                  </MoreLink> */}
                </ExampleOfUse>

                <ExampleOfUse>
                  <ExampleOfUseImg
                    fluid={WorkFluidImages('valveMaintenanceSystem')}
                  />
                  <h4 className="example-of-use-title">
                    次世代バルブメンテナンスシステムの開発と運用
                  </h4>
                  <p className="example-of-use-txt">
                    様々な種類のバルブを一元管理するバルブメンテナンスシステムをAWS上に構築。バルブ診断装置と連携したIoTシステムとして継続的な開発とデプロイを行っている。
                  </p>
                  {/* TODO:下層ページができたらここも繋げるのかなどを検討する。 20210331 */}
                  {/* <MoreLink alt="" to="/works/valve-maintenance-system">
                    事例を詳しく見る
                  </MoreLink> */}
                </ExampleOfUse>

                <ExampleOfUse>
                  <ExampleOfUseImg
                    fluid={WorkFluidImages('productionManagement')}
                  />
                  <h4 className="example-of-use-title">
                    生産管理自動化システムの開発
                  </h4>
                  <p className="example-of-use-txt">
                    生産ラインの自動化を行う機能群をマイクロサービスとしてKubernetes上に実装。製造現場からもセキュアにアクセスできるシステムとして構築。
                  </p>
                  {/* TODO:下層ページができたらここも繋げるのかなどを検討する。 20210331 */}
                  {/* <MoreLink alt="" to="">
                    事例を詳しく見る
                  </MoreLink> */}
                </ExampleOfUse>
              </ExampleOfUseWrap>
            </ExampleOfUseArea>
          </TechDescription>
        </SectionWrapper>
      </div>
    </PageRoot>
  );
};

export default HomePage;
